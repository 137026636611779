/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";
import "../App.css";
import "../table.css"
import { Button } from "../components/button/button";
import { useNavigate } from "react-router-dom/dist";
import { useLocation } from "react-router-dom/dist";


function Resultado_Tabela_produtividade() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const simulador = state.simulador;
    const resultados_tabela_desf = state.resultados_tabela_desf;
    const resultados_tabela_prod = state.resultados_tabela_prod;
    const desmameMinimo = state.desmameMinimo;
    const desmameMaximo = state.desmameMaximo;

    const handleSubmit = () => {
      navigate("/gera_tabela_produtividade", { state: { simulador: simulador } });
    }
    return(
      <div  className="content">

      <div className="div1 paddint_bot"> 
          <h1> Simulações de produtividade</h1>
      </div>
      
    <table border="0" cellpadding="2" cellspacing="0" id="sheet0" class="sheet0 gridlines">

    <tbody>
      <tr class="row0">
        <td class="column0 style2 s style2 strong" colspan="3">Cenários</td>
        <td class="column3 style2 s style2 strong" colspan="6">Produtividade Global</td>
      </tr>
      <tr class="row1">
        <td class="column0 style3 s style5 strong" rowspan="2">TN(%)</td>
        <td class="column1 style3 s style5 strong" rowspan="2">IAC (anos)</td>
        <td class="column2 style3 s style5 strong" rowspan="2">IAB (anos)</td>
        <td class="column3 style3 s style3 strong" colspan="5">PH (Kg PV há¹)</td>
        <td class="column8 style3 s style5 strong" rowspan="2">TD(%)</td>
      </tr>
      <tr class="row2">
        <td class="column3 style8 n strong">{(simulador.unidade_animal*0.5).toFixed(0)}</td>
        <td class="column4 style8 n strong">{(simulador.unidade_animal*0.75).toFixed(0)}</td>
        <td class="column5 style8 n strong">{(simulador.unidade_animal).toFixed(0)}</td>
        <td class="column6 style8 n strong">{(simulador.unidade_animal*1.25).toFixed(0)}</td>
        <td class="column7 style8 n strong">{(simulador.unidade_animal*1.50).toFixed(0)}</td>
      </tr>
      <tr class="row3">
        <td class="column0 style3 n style4 strong" rowspan="9">{desmameMinimo}</td>
        <td class="column1 style3 n style4 strong" rowspan="3">3</td>
        <td class="column2 style7 n strong">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[0].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[1].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[2].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[3].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[4].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[0].toFixed(2)}</td>
      </tr>
      <tr class="row4">
        <td class="column2 style7 n strong">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[5].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[6].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[7].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[8].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[9].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[1].toFixed(2)}</td>
      </tr>
      <tr class="row5">
        <td class="column2 style8 n  strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[10].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[11].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[12].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[13].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[14].toFixed(2)}</td>
        <td class="column8 style8 n">{resultados_tabela_desf[2].toFixed(2)}</td>
      </tr>
      <tr class="row6">
        <td class="column1 style3 n style5  strong" rowspan="3">2</td>
        <td class="column2 style6 n strong ">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[15].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[16].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[17].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[18].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[19].toFixed(2)}</td>
        <td class="column8 style6 n">{resultados_tabela_desf[3].toFixed(2)}</td>
      </tr>
      <tr class="row7">
        <td class="column2 style6 n strong ">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[20].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[21].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[22].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[23].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[24].toFixed(2)}</td>
        <td class="column8 style6 n">{resultados_tabela_desf[4].toFixed(2)}</td>
      </tr>
      <tr class="row8">
        <td class="column2 style6 n strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[25].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[26].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[27].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[28].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[29].toFixed(2)}</td>
        <td class="column8 style6 n">{resultados_tabela_desf[5].toFixed(2)}</td>
      </tr>
      <tr class="row9">
        <td class="column1 style3 n style4 strong" rowspan="3">1</td>
        <td class="column2 style6 n strong">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[30].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[31].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[32].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[33].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[34].toFixed(2)}</td>
        <td class="column8 style6 n">{resultados_tabela_desf[6].toFixed(2)}</td>
      </tr>
      <tr class="row10">
        <td class="column2 style6 n strong">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[35].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[36].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[37].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[38].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[39].toFixed(2)}</td>
        <td class="column8 style6 n">{resultados_tabela_desf[7].toFixed(2)}</td>
      </tr>
      <tr class="row11">
        <td class="column2 style6 n strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[40].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[41].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[42].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[43].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[44].toFixed(2)}</td>
        <td class="column8 style6 n">{resultados_tabela_desf[8].toFixed(2)}</td>
      </tr>
      <tr class="row12">
        <td class="column0 style3 n style4 strong " rowspan="9">{(desmameMaximo+desmameMinimo)/2}</td>
        <td class="column1 style3 n style4 strong " rowspan="3">3</td>
        <td class="column2 style7 n strong strong">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[45].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[46].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[47].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[48].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[49].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[9].toFixed(2)}</td>
      </tr>
      <tr class="row13">
        <td class="column2 style7 n strong">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[50].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[51].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[52].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[53].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[54].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[10].toFixed(2)}</td>
      </tr>
      <tr class="row14">
        <td class="column2 style7 n strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[55].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[56].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[57].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[58].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[59].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[11].toFixed(2)}</td>
      </tr>
      <tr class="row15">
        <td class="column1 style3 n style4 strong" rowspan="3">2</td>
        <td class="column2 style7 n strong">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[60].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[61].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[62].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[63].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[64].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[12].toFixed(2)}</td>
      </tr>
      <tr class="row16">
        <td class="column2 style7 n strong">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[65].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[66].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[67].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[68].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[69].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[13].toFixed(2)}</td>
      </tr>
      <tr class="row17">
        <td class="column2 style7 n strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[70].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[71].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[72].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[73].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[74].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[14].toFixed(2)}</td>
      </tr>
      <tr class="row18">
        <td class="column1 style3 n style4 strong" rowspan="3">1</td>
        <td class="column2 style7 n strong">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[75].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[76].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[77].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[78].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[79].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[15].toFixed(2)}</td>
      </tr>
      <tr class="row19">
        <td class="column2 style7 n strong">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[80].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[81].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[82].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[83].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[84].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[16].toFixed(2)}</td>
      </tr>
      <tr class="row20">
        <td class="column2 style7 n strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[85].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[86].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[87].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[88].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[89].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[17].toFixed(2)}</td>
      </tr>
      <tr class="row21">
        <td class="column0 style3 n style5 strong" rowspan="9">{desmameMaximo}</td>
        <td class="column1 style3 n style4 strong" rowspan="3">3</td>
        <td class="column2 style7 n strong">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[90].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[91].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[92].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[93].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[94].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[18].toFixed(2)}</td>
      </tr>
      <tr class="row22">
        <td class="column2 style7 n strong">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[95].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[96].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[97].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[98].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[99].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[19].toFixed(2)}</td>
      </tr>
      <tr class="row23">
        <td class="column2 style7 n strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[100].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[101].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[102].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[103].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[104].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[20].toFixed(2)}</td>
      </tr>
      <tr class="row24">
        <td class="column1 style3 n style4 strong" rowspan="3">2</td>
        <td class="column2 style7 n strong">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[105].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[106].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[107].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[108].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[109].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[21].toFixed(2)}</td>
      </tr>
      <tr class="row25">
        <td class="column2 style7 n strong">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[110].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[111].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[112].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[113].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[114].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[22].toFixed(2)}</td>
      </tr>
      <tr class="row26">
        <td class="column2 style7 n strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[115].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[116].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[117].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[118].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[119].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[23].toFixed(2)}</td>
      </tr>
      <tr class="row27">
        <td class="column1 style3 n style5 strong" rowspan="3">1</td>
        <td class="column2 style7 n strong">3</td>
        <td class="column3 style7 n">{resultados_tabela_prod[120].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[121].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[122].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[123].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[124].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[24].toFixed(2)}</td>
      </tr>
      <tr class="row28">
        <td class="column2 style7 n strong">2</td>
        <td class="column3 style7 n">{resultados_tabela_prod[125].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[126].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[127].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[128].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[129].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[25].toFixed(2)}</td>
      </tr>
      <tr class="row29">
        <td class="column2 style7 n strong">1</td>
        <td class="column3 style7 n">{resultados_tabela_prod[130].toFixed(2)}</td>
        <td class="column4 style7 n">{resultados_tabela_prod[131].toFixed(2)}</td>
        <td class="column5 style7 n">{resultados_tabela_prod[132].toFixed(2)}</td>
        <td class="column6 style7 n">{resultados_tabela_prod[133].toFixed(2)}</td>
        <td class="column7 style7 n">{resultados_tabela_prod[134].toFixed(2)}</td>
        <td class="column8 style7 n">{resultados_tabela_desf[26].toFixed(2)}</td>
      </tr>

    </tbody>
    </table>
        
    <div className="div4 flex_line flex_jc_center">
      <Button
        texto="Voltar"
        props_style="secondary"
        onClick={handleSubmit} 
      />     


    </div>

    </div>
    
  );
}
export default Resultado_Tabela_produtividade;
