/* eslint-disable react/jsx-pascal-case */
import '../App.css';
import { Button } from '../components/button/button';
import { Button_Increment } from '../components/button_increment/button_increment';
import { useState } from 'react';
import { Chart } from "react-google-charts";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


  
  export const options = {
    hAxis: {
      title: "Desmame",
    },
    vAxis: {
      title: "Produtividade",
    },
    series: {
      1: { curveType: "function" },
    },
    chartArea: {
      width:'80%',
      height:'80%'
    }
  };

  const grafico = (data) =>{     
   return  <Chart
      width= "100%"
      height= {200}
      chartType="LineChart"
      data={data}
      options={options}
    />
  }



function Sensibilidade() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const simulador = state.simulador;

  const [data, setData] = useState([
    ['Year', 'Sales'],
    ['2016', 100],
    ['2017', 200],
    ['2018', 300],
    ['2019', 400],
  ]);


  //guarda estado do simulador
  var guarda_desmame = simulador.desmame;
  var guarda_mortalidade = simulador.mortalidade;
  var guarda_idade_venda = simulador.idade_venda;
  var guarda_idade_entoure = simulador.idade_entoure;
  var guarda_unidade_animal = simulador.unidade_animal;
  var guarda_lotacao = simulador.lotacao;
  var guarda_touros = simulador.touros;
  
  var metas = {
    vacas: 1000,
    tmf: 1000,
    novilho: 1000,
    area: simulador.area_area_total
  };


    const [opcao, setOpcao] = useState('taxa-desmame');

    const handleOpcaoChange = (event) => {
      setOpcao(event.target.value);
    };

    const [ValorMinimo, setValorMinimo] = useState(60);
    const [ValorMaximo, setValorMaximo] = useState(80);


 const calcula = () => {
  var count = 1;
  var newData = [["x", ""]]
    if(opcao==='taxa-desmame'){
      if(ValorMaximo>ValorMinimo){
        for(var i = ValorMinimo; i <= ValorMaximo; i ++){

          simulador.set_base_info({
            desmame: i,
            mortalidade: simulador.mortalidade,
            touros: simulador.touros,
            idade_entoure: simulador.idade_entoure,
            idade_venda: simulador.idade_venda,
            lotacao: simulador.lotacao,
            unidade_animal: simulador.unidade_animal,
          });
          simulador.executa_simulador(metas);
          newData.push([count.toString(),simulador.produtividade_por_area.toString()])
          console.log("AAAAAAAAAA")
          count++;
        }
        setData(newData);
        console.log(data)
      }
    if(ValorMaximo<ValorMinimo){
      for(var i = ValorMinimo; i===ValorMaximo; i --){

        simulador.set_base_info({
          desmame: i,
          mortalidade: simulador.mortalidade,
          touros: simulador.touros,
          idade_entoure: simulador.idade_entoure,
          idade_venda: simulador.idade_venda,
          lotacao: simulador.lotacao,
          unidade_animal: simulador.unidade_animal,
        });
        simulador.executa_simulador(metas);
        data.push([count,simulador.produtividade_por_area])
        count++;
      }
    }
  }
    if(opcao==='taxa-mortalidade'){
      if(ValorMaximo>ValorMinimo){
        for(var i = ValorMinimo; i===ValorMaximo; i ++){

          simulador.set_base_info({
            desmame: simulador.desmame,
            mortalidade: i,
            touros: simulador.touros,
            idade_entoure: simulador.idade_entoure,
            idade_venda: simulador.idade_venda,
            lotacao: simulador.lotacao,
            unidade_animal: simulador.unidade_animal,
          });
          data.push([count,simulador.produtividade_por_area])
          count++;
        }
      }
    if(ValorMaximo<ValorMinimo){
      for(var i = ValorMinimo; i===ValorMaximo; i --){

        simulador.set_base_info({
          desmame: simulador.desmame,
          mortalidade: i,
          touros: simulador.touros,
          idade_entoure: simulador.idade_entoure,
          idade_venda: simulador.idade_venda,
          lotacao: simulador.lotacao,
          unidade_animal: simulador.unidade_animal,
        });

        data.push([count,simulador.produtividade_por_area])
        count++;
      }
    }
  }

  simulador.set_base_info({
    desmame: guarda_desmame,
    mortalidade: guarda_mortalidade,
    touros: guarda_touros,
    idade_entoure: guarda_idade_entoure,
    idade_venda: guarda_idade_venda,
    lotacao: guarda_lotacao,
    unidade_animal: guarda_unidade_animal,
  });
  simulador.executa_simulador(metas);

  grafico();
}








    const handleSubmit_3 = () => {
      navigate("/menu_principal", { state: { simulador: simulador } });
    };

    return (
        <div class="content">
              <div className="div1"> 
                  <h1> Sensibilidade </h1>
          
                  <div className="paragraphs">
                      <p> Nesta etapa, simuladas mudanças individuais nos indicadores zootécnicos </p>
                  </div>
              </div>
            <div className="div2 flex_jc_spacearound width_50" >
                
                <div className='flex_block_column bg-grey'>
                <h2>Selecione uma opção:</h2>
                    <label>
                        <input
                            type="radio"
                            value="taxa-desmame"
                            checked={opcao === 'taxa-desmame'}
                            onChange={handleOpcaoChange}
                            className='input '
                        />
                        Taxa de desmame
                    </label>
                    
                    <label>
                        <input
                          type="radio"
                          value="idade-abate"
                          checked={opcao === 'idade-abate'}
                          onChange={handleOpcaoChange}
                          className='input'
                        />
                        Idade de abate
                    </label>

                    <label>
                        <input
                          type="radio"
                          value="idade-acasalamento"
                          checked={opcao === 'idade-acasalamento'}
                          onChange={handleOpcaoChange}
                          className='input'
                        />
                        Idade de entoure
                    </label>
                    
                    <label>
                        <input
                          type="radio"
                          value="taxa-mortalidade"
                          checked={opcao === 'taxa-mortalidade'}
                          onChange={handleOpcaoChange}
                          className='input'
                        />
                        Taxa de mortalidade
                    </label>
                </div>

                {opcao === "taxa-desmame" && <div className='flex_block_column flex_jc_evenly'>

                
                    <div className='flex_block bg-grey'>
                        <p> Desmame mínimo </p>
                        <Button_Increment min={0} max={100} step={1} value={ValorMinimo} setValue={setValorMinimo}/>

                    </div>

                    <div className='flex_block bg-grey'>
                        <p> Desmame máximo </p>
                        <Button_Increment min={0} max={100} step={1} value={ValorMaximo} setValue={setValorMaximo}/>
                    </div>
                </div>
                }

                {opcao === 'taxa-mortalidade' && 
                  <div className='flex_block_column flex_jc_evenly'>
                      <div className='flex_block bg-grey'>
                          <p> Mortalidade mínima </p>
                          <Button_Increment min={0} max={20} step={1} value={ValorMinimo} setValue={setValorMinimo}/>
                      </div>

                      <div className='flex_block bg-grey'>
                          <p> Mortalidade máxima </p>
                          <Button_Increment min={0} max={20} step={1} value={ValorMaximo} setValue={setValorMaximo}/>
                      </div>

                      </div>
                }
                {opcao === 'idade-abate' && 
                  <div className='flex_block_column flex_jc_evenly'>
                      <div className='flex_block bg-grey'>
                          <p> Idade abate mínima </p>
                          <Button_Increment min={1} max={3} step={1} value={ValorMinimo} setValue={setValorMinimo}/>
                      </div>

                      <div className='flex_block bg-grey'>
                          <p> Idade abate máxima </p>
                          <Button_Increment min={1} max={3} step={1} value={ValorMaximo} setValue={setValorMaximo}/>
                      </div>

                      </div>
                }
                {opcao === 'idade-acasalamento' && 
                  <div className='flex_block_column flex_jc_evenly'>
                      <div className='flex_block bg-grey'>
                          <p> Idade de entoure mínima </p>
                          <Button_Increment min={1} max={3} step={1} value={ValorMinimo} setValue={setValorMinimo}/>
                      </div>

                      <div className='flex_block bg-grey'>
                          <p> Idade de entoure máxima </p>
                          <Button_Increment min={1} max={3} step={1} value={ValorMaximo} setValue={setValorMaximo}/>
                      </div>

                      </div>
                }  
                <div className='flex_block'>
                  <Button
                  texto="Calcular"
                  props_style="primary"
                  onClick={calcula}
                  /></div>

                  </div>
              
            <div className="div3 width">
                {grafico(data)}
            </div>
              
              
              <div className="div4 flex_line flex_jc_center">

              <Button
                texto="Voltar"
                props_style="secondary"
                onClick={handleSubmit_3}
              /> 
              </div>
                  
  
          </div>
      
      );
  }
  

export default Sensibilidade;
