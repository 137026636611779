import React from 'react';
import './img.css'

const Imagem = ({ src, alt }) => {
  return (
    
      <img src={src} alt={alt}  className='img_css'/>
    
  );
};

export default Imagem;
