/* eslint-disable react/jsx-pascal-case */
import '../App.css';
import { Button } from '../components/button/button'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function resultado_metas() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const simulador = state.simulador;
    const metas = state.metas;
    const handleSubmit = () => {
        navigate("/atingir_metas", { state: { simulador: simulador } });
      }


  return (
      <div className="parent">
        <div className='div1'>
            <h1> Resultados das Metas</h1>
            <div className="paragraphs">
              <p>Abaixo, estão os resultados das metas criadas na página anterior.</p>
                </div>
            </div>

        <div className='div2'>
            <div className="flex_block bg-grey margin_custom_5 width">
                
                <p > Para entourar a quantidade de <b>{metas.vacas} vacas</b>, você precisa de aproximadamente <b>{simulador.vacas_area_total.toFixed(0)}</b> hectares de área de pastagem produtiva. </p>
            </div>

            <div className="flex_block bg-grey margin_custom_5 width">
                <p> O tamanho estimado de um rebanho com <b>área igual a {metas.area}</b> hectares produtivos é de aproximadamente <b>{simulador.area_unidades_total.toFixed(0)} animais</b>. </p>
            </div> 

            <div className="flex_block bg-grey margin_custom_5 width">
                <p> Para <b>desmamar {metas.tmf} terneiros ao ano</b> é necessário aproximadamente <b>{simulador.tmf_unidades_total.toFixed(0)} hectares</b> de área de pastagem produtiva. </p>
            </div>
 
            <div className="flex_block bg-grey margin_custom_5 width">
                <p> Para vender <b>{metas.novilho} unidades de novilhos</b>, é necessário entourar aproximadamente <b>{simulador.novilho_unidades_vacas.toFixed(0)}</b> vacas. </p>

            </div>

        </div> 
        <div className='div3'>
            </div>
        <div className='div4 margin_custom_5'>
            <div className="flex_line flex_jc_spacearound margin_custom_5">
            <Button 
                texto="Voltar" 
                props_style="secondary" 
                onClick={handleSubmit} 
            />
            </div> 
        </div>   
        </div>
    
    );
}

export default resultado_metas;
