/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";
import "../App.css";
import { Button } from "../components/button/button";
import { Button_Increment } from "../components/button_increment/button_increment";
import { useNavigate } from "react-router-dom/dist";
import { useLocation } from "react-router-dom/dist";

function Tabela_produtividade() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const simulador = state.simulador;

    //guarda estado do simulador
    var guarda_desmame = simulador.desmame;
    var guarda_mortalidade = simulador.mortalidade;
    var guarda_idade_venda = simulador.idade_venda;
    var guarda_idade_entoure = simulador.idade_entoure;
    var guarda_unidade_animal = simulador.unidade_animal;
    var guarda_lotacao = simulador.lotacao;
    var guarda_touros = simulador.touros;

  

    const resultados_tabela_prod = [];
    const resultados_tabela_desf = [];
    var metas = {
      vacas: 1000,
      tmf: 1000,
      novilho: 1000,
      area: simulador.area_area_total
    };



    const gera_tabela = () =>{
      var calc = unidadeAnimal*0.5;
      var cald = unidadeAnimal*1.5;
      

      for (var i = desmameMinimo; i <= desmameMaximo; i= i + ((desmameMaximo-desmameMinimo)/2)){
        for(var j = 3; j >= 1; j--){
          for(var k = 3; k >=1; k--){
            for(var l = calc; l <= cald; l=l+(unidadeAnimal*0.25) ){

                simulador.set_base_info({
                  desmame: i,
                  mortalidade: percentualMortalidade,
                  touros: percentualTouros,
                  idade_entoure: j,
                  idade_venda: k,
                  lotacao: lotacao,
                  unidade_animal: l,
                });

                simulador.executa_simulador(metas);
                resultados_tabela_prod.push(simulador.produtividade_por_area)

          }
          resultados_tabela_desf.push(simulador.desfrute_percentual_kg)
        }      
        
      }
    }

    simulador.set_base_info({
      desmame: guarda_desmame,
      mortalidade: guarda_mortalidade,
      touros: guarda_touros,
      idade_entoure: guarda_idade_entoure,
      idade_venda: guarda_idade_venda,
      lotacao: guarda_lotacao,
      unidade_animal: guarda_unidade_animal,
    });
    simulador.executa_simulador(metas);
    navigate("/result_tabela_produtividade", { state: { resultados_tabela_prod: resultados_tabela_prod, resultados_tabela_desf: resultados_tabela_desf, simulador: simulador , desmameMinimo: desmameMinimo, desmameMaximo:desmameMaximo } });
  }

    const [percentualMortalidade, setPercentualMortalidade] = useState(
        simulador.mortalidade
      );
      const [lotacao, setlotacao] = useState(
        simulador.lotacao
      );

      const [percentualTouros, setpercentualTouros] = useState(
        simulador.touros
      );
    const [unidadeAnimal, setunidadeAnimal] = useState(
      simulador.unidade_animal
    );
    const [desmameMinimo, setdesmameMinimo] = useState(50);
    const [desmameMaximo, setdesmameMaximo] = useState(80);


  

    const handleSubmit = () => {
      navigate("/menu_principal", { state: { simulador: simulador } });
    }
  
    return (


      <div class="content">
        <div className="div1">
          <h1> Tabela de Produtividade </h1>
  
          <div className="paragraphs">
            <p> Gerar uma tabela de relações dos indíces zootécnicos com a produtividade </p>
          </div>
        </div>
  
        <div className="div2_var_1_">
        <div className="flex_line  flex_jc_center width_50">
            <div className="flex_block flex_wrap flex_jc_center bg-grey">
              <p className="p_center margin_bot"> Taxa de mortalidade </p>
  
              <div className="flex_block">
                <Button_Increment
                  min={0}
                  max={100}
                  step={1}
                  value={percentualMortalidade}
                  setValue={setPercentualMortalidade}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="flex_line  flex_jc_center width_50">
            <div className="flex_block flex_wrap flex_jc_center bg-grey">
              <p className="p_center margin_bot"> Unidade animal </p>
  
              <div className="flex_block">
                <Button_Increment
                  min={0}
                  max={100}
                  step={1}
                  value={unidadeAnimal}
                  setValue={setunidadeAnimal}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="flex_line  flex_jc_center width_50">
            <div className="flex_block flex_wrap flex_jc_center bg-grey">
              <p className="p_center margin_bot"> Taxa de desmame </p>
  
              <div className="flex_block">
                <p> Mínima </p>
                <Button_Increment
                  min={0}
                  max={100}
                  step={1}
                  value={desmameMinimo}
                  setValue={setdesmameMinimo}
                />
              </div>
  
              <div className="flex_block">
                <p> Máxima </p>
                <Button_Increment
                  min={0}
                  max={100}
                  step={1}
                  value={desmameMaximo}
                  setValue={setdesmameMaximo}
                />
              </div>
            </div>
          </div>
        
  
          <div className="flex_line  flex_jc_center width_50">
            <div className="flex_block flex_wrap flex_jc_center bg-grey">
              <p className="p_center margin_bot"> Idade de abate </p>
  
              <div className="flex_block">
                <p> Mínima </p>
                <Button_Increment
                  min={1}
                  max={1}
                  step={1}
                  value={1}
                  setValue={0}
                  disabled={true}
                />
              </div>
  
              <div className="flex_block">
                <p> Máxima </p>
                <Button_Increment
                  min={1}
                  max={3}
                  step={1}
                  value={3}
                  setValue={0}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        
          <div className="flex_line  flex_jc_center width_50">
            <div className="flex_block flex_wrap flex_jc_center bg-grey">
              <p className="p_center margin_bot"> Idade de entoure </p>
              <div className="flex_block">
                <p> Mínima </p>
                <Button_Increment
                  min={1}
                  max={1}
                  step={1}
                  value={1}
                  setValue={0}
                  disabled={true}
                />
              </div>
  
              <div className="flex_block">
                <p> Máxima </p>
                <Button_Increment
                  min={3}
                  max={3}
                  step={1}
                  value={3}
                  setValue={0}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        
        </div>
          <div className="flex_line  flex_jc_center width_50">
            <div className="flex_block flex_wrap flex_jc_center">
  
            <div className="div4">
                <div className="flex_line_var_1_ flex_jc_spacebetween">
                    <Button
                      texto="Voltar"
                      props_style="secondary"
                      onClick={ handleSubmit}
                    />
                    <Button
                      texto="Gerar!"
                      props_style="primary"
                      onClick={ gera_tabela }
                    />
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }

export default Tabela_produtividade;
