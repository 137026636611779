/* eslint-disable react/jsx-pascal-case */
import '../App.css';
import { Button } from '../components/button/button';
import { Button_Increment } from '../components/button_increment/button_increment';
import { useLocation } from "react-router-dom/dist";
import { useNavigate } from "react-router-dom/dist";

function resultado_limite_investimento() {

    const { state } = useLocation();
    const navigate = useNavigate();
    const simulador = state.simulador;
    const simulador_2 = state.simulador_2;
    const ganho_cenario_1 = state.ganho_cenario_1;
    const ganho_cenario_2 = state.ganho_cenario_2;
    
    const handleSubmit = () => {
      navigate("/limite_investimento", { state: { simulador: simulador,  simulador_2 : simulador_2 } });
    }
    const handleSubmit_2 = () => {
      navigate("/estoque_animal_ca", { state: { simulador: simulador , simulador_2 : simulador_2, ganho_cenario_1: ganho_cenario_1, ganho_cenario_2: ganho_cenario_2 } });
    }
    const handleSubmit_3 = () => {
      navigate("/estoque_animal_cf", { state: { simulador: simulador , simulador_2 : simulador_2, ganho_cenario_1: ganho_cenario_1, ganho_cenario_2: ganho_cenario_2  } });
    }

  return (
    <div className="parent">
        <div className='div1'>
            <h1> Resultado </h1>
            <div className="paragraphs ">
              <p className="p_center"> Diferença de faturamento entre os cenários </p>
            </div>
        </div>
        <div className='div2 '>
            <div className="flex_line flex_jc_center height p_center">
                <div className=".flex_block_var_1_ bg-grey border output_li  margin">
                    <p className='strong p_center margin_top_2'> Faturamento do Cenário Base</p>
                    <span>R$ {(simulador.produtividade_por_area*ganho_cenario_1).toFixed(2)}</span>
                </div>
            </div>
            <div className="flex_line flex_jc_center p_center ">
                <div className=".flex_block_var_1_ bg-grey border output_li  margin ">
                    <p className='strong p_center margin_top_2'> Faturamento do Cenário Futuro</p>
                    <span>R$ {(simulador_2.produtividade_por_area*ganho_cenario_2).toFixed(2)}</span>
                </div>
            </div>
            
            <div className='div2 '>
            <div className="flex_line flex_jc_center p_center height">
                <div className=".flex_block_var_1_ bg-grey height border output_li margin">
                    <p className='strong p_center margin_top_2'> Limite de investimento</p>
                    <span>R$ {((simulador_2.produtividade_por_area*ganho_cenario_2) - (simulador.produtividade_por_area*ganho_cenario_1)).toFixed(2) }</span>
                </div>
            </div></div> 
        </div>
        <div className='div3'>
      
        </div>
    
        <div className='div4'>    
    
            <div className="flex_line_var_1_ flex_jc_spacebetween">
                
                <Button
                    texto="Voltar"
                    props_style="secondary"
                    onClick={handleSubmit}
                />
                <Button
                    texto="Estoque atual"
                    props_style="tertiary"
                    onClick={handleSubmit_2}
                />

                <Button
                    texto="Estoque futuro"
                    props_style="tertiary"
                    onClick={handleSubmit_3}
                />
            </div>
        </div>
        

    </div>
    
    );
}

export default resultado_limite_investimento;
